import React from 'react';
import { Row, Col } from 'antd';
import Banner3 from '../img/banner3.png'
import Video from '../img/demo_ouverture_compte.mp4'
import TransfertArgent from '../img/transfert_argent.png'
import RetirerGratuitement from '../img/retirer_gratuitement.png'
import AcheterCreditAgricole from '../img/acheter_credit_agricole.png'
import PayezMarchand from '../img/payez_marchand.png'
import HomeCashout from '../img/home_cashout.png'
import Partenaire1 from '../img/partenaire_1.png'
import Playgoogle from '../img/playgoogle.png'
import Appstore from '../img/appstore.png'
import Partenaire2 from '../img/partenaire_2.png'
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer/index';
import Header from '../../Components/Header/index';

export default function wizallMoney() {
    return (
        <>
            <Header />
            <div className="wizallMoney">
                <div className="section-1">
                    <img alt="" src={Banner3} />
                </div>

                <div className="section-2">
                    <div className="container">
                        <h1 className="fadeIn wow">Utilisez nos applications et facilitez-vous la vie</h1>
                        <div className="video">
                            <video playsInline="playsInline" controls loop="loop" width="100%" height="">
                                <source src={Video} alt="" type="video/mp4" />
                            </video>
                        </div>

                        <Row>
                            <Col lg={24}>
                                <h6>Avec l'application Wizall Money tout est plus simple, facile et surtout gratuit :</h6>
                                <ul className="desktop">
                                    <li className="fadeIn wow">
                                        <p><img src={TransfertArgent} alt="" className="icon" /> <strong> Transférez de l'argent</strong> vers un compte Wizall Money  ou vers n'importe quel <br></br> numéro (un code de retrait est envoyé)</p>
                                    </li>

                                    <li className="fadeIn wow">
                                        <p><img src={RetirerGratuitement} alt="" className="icon" /> <strong>Payez un marchand</strong> et effectuez une collecte,  en un clic</p>
                                    </li>
                                    <li className="fadeIn wow">
                                        <p><img src={AcheterCreditAgricole} alt="" className="icon" /> <strong> Achetez du crédit téléphonique</strong> chez tous les opérateurs (pour vous ou pour un ami)  <img src={Partenaire1} alt="" /> </p>
                                    </li>

                                    <li className="fadeIn wow">
                                        <p><img src={PayezMarchand} alt="" className="icon" /> <strong>Payez vos factures</strong> d'eau et d'électricité, de télévision, de carte péage et <br />  ajoutez les en favoris (pour recevoir une notification quand une facture arrive). <img src={Partenaire2} className="img-facture" alt="" />
                                        </p>
                                    </li>

                                    <li className="fadeIn wow">
                                        <p><img src={HomeCashout} alt="" className="icon" /><strong>Retirez gratuitement</strong> votre argent dans <Link to={'/reseau-agents'} className="link_reseau">notre réseau d'agents </Link></p>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="section-3">
                    <div className="container">
                        <h4>Téléchargez l'application:</h4>
                        <div className="store">
                            <a href="https://play.google.com/store/apps/details?id=com.wizall.wizallclient&amp;referrer=utm_source%3DReferent%26utm_medium%3Dwizallsite%26utm_campaign%3DSite_acquisition%26anid%3Dadmo" target="_blank" rel="noopener noreferrer">
                                <img src={Playgoogle} alt="" />
                            </a>
                            <a href="https://apps.apple.com/us/app/wizall-money/id1203292720?l=fr&amp;ls=1" target="_blank" rel="noopener noreferrer">
                                <img src={Appstore} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}