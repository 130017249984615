import React from 'react';
import { NavLink } from "react-router-dom";
import logo from '../../Components/img/logo.png'
import { Layout } from 'antd';
import ResponsiveAntMenu from 'responsive-ant-menu';
import 'antd/dist/antd.css';
import { Menu } from 'antd';
import menuClose from '../../Components/img/menu_close.png';
import menuOpen from '../../Components/img/menu_open.png';

const { Header } = Layout;


export default function Head() {
    return (
        <Layout className="layout">
            <Header>
                <div className="navbar-mobile">
                    <ResponsiveAntMenu
                        placement="leftBottom"
                        mobileMenuContent={isMenuShown => isMenuShown ? <a href><img className="icon-menu" alt="" src={menuClose} /></a> : <a href><img className="icon-menu" alt="" src={menuOpen} /></a>}
                        menuClassName={'responsive-ant-menu'}
                        mode={'vertical'}>
                        {() =>
                            <Menu mode="" selectable={false}>
                                <Menu.Item><NavLink to={'/accueil'}>Accueil</NavLink></Menu.Item>
                                <Menu.Item><NavLink to='/wizall-money' className="nav-link">Wizall Money</NavLink></Menu.Item>
                                <Menu.Item><NavLink to='/wizall-money-pro' className="nav-link">Wizall Money Pro</NavLink></Menu.Item>
                                <Menu.Item><NavLink to='/reseau-agents' className="nav-link">Réseau d'agents</NavLink></Menu.Item>
                                <Menu.Item><NavLink to='/aides-contact' className="nav-link">Aides & Contact</NavLink></Menu.Item>
                            </Menu>
                        }
                    </ResponsiveAntMenu>
                </div>

                <div className="navbar">
                    <NavLink to={'/'}>
                        <img src={logo} alt="" className="img-fluid logo" />
                    </NavLink>
                    <Menu mode="horizontal" selectable={false}>
                        <Menu.Item><NavLink to={'/accueil'}>Accueil</NavLink></Menu.Item>
                        <Menu.Item><NavLink to='/wizall-money' className="nav-link">Wizall Money</NavLink></Menu.Item>
                        <Menu.Item><NavLink to='/wizall-money-pro' className="nav-link">Wizall Money Pro</NavLink></Menu.Item>
                        <Menu.Item><NavLink to='/reseau-agents' className="nav-link">Réseau d'agents</NavLink></Menu.Item>
                        <Menu.Item><NavLink to='/aides-contact' className="nav-link">Aides & Contact</NavLink></Menu.Item>
                    </Menu>
                </div>
            </Header>
        </Layout>
    );
}