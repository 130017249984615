import React from 'react';
import { Row, Col } from 'antd';

export default function MentionLegales() {
    return (
        <div className="mention-legales">
            <div className="container">
                <Row>
                    <Col lg={24} className="blc">
                        <h1> Protection des données Personnelles </h1>
                        <h3>PREAMBULE</h3>
                        <p>
                            Afin de préserver la vie privée et la protection des Données à Caractère Personnel de tous (personnel
                            Wizall, ses filiales et sa clientèle), Wizall, en sa qualité de responsable de traitement, met en place
                            cette charte qui s’inscrit dans son cadre réglementaire applicable.<br />
                            La présente Charte a pour objet de vous informer sur les données à caractère personnel vous concernant
                            que nous collectons, les raisons pour lesquelles nous les utilisons, avec qui nous les partageons,
                            pendant combien de temps nous les conservons, quels sont vos droits et comment les exercer.
                            Des informations complémentaires peuvent vous être communiquées, le cas échéant, lorsque vous
                            souscrivez à un produit ou service en particulier
                        </p>
                        <h3>1. Nos engagements</h3>
                        <p>
                            <ul>
                                <li>Nous respectons les modalités de traitement des données à caractère personnel, qui
                                    prennent en compte les finalités du traitement, les durées de conservation de vos données,
                                    l’exercice de vos droits en matière de protection des données à caractère personnel et les
                                    destinataires de vos données ;</li>
                                <li>Nous utilisons uniquement vos données pour des finalités qui vous seront décrites dans ce
                                    document ;</li>
                                <li>Seules vos données à caractère personnel utiles sont collectées ; </li>
                                <li>Nous ne conservons pas vos données au-delà de la durée nécessaire pour les opérations
                                    pour lesquelles elles ont été collectées, ou de celles prévues soit par la règlementation ou
                                    la loi en vigueur ;</li>
                                <li>Nous vous garantissons vos droits à la protection de vos données personnelles et comment
                                    les exercer ;</li>
                                <li>Nous engageons au traitement de vos données de manière confidentielle et protégées ;</li>
                                <li>Nous vous assurons également l’information sur les destinataires, avec qui nous
                                    partageons vos données personnelles.</li>
                            </ul>
                        </p>
                        <h3>2. Quelles données collectons-nous ?</h3>
                        <p>Nous ne collectons que des données strictement nécessaires au regard de la finalité du traitement envisagée.</p>
                        <h4>Section 1 : Les données à caractère personnel collectées de manière directe</h4>
                        <i>Les données collectées de manière directes sont :</i>
                        <ul>
                            <li>Informations d’identification (nom, carte d’identité et numéro de passeport, nationalité, lieu et
                                date de naissance, genre, photographie, adresse IP);</li>
                            <li>Coordonnées (adresse postale et adresse mail, numéro de téléphone) ;</li>
                            <li>Situation familiale (statut marital, nombre d’enfants) ;</li>
                            <li>Informations relatives à l’éducation et à l’emploi (niveau d’étude, emploi, nom de l’employeur) </li>
                            <li>Données bancaires, financières et transactionnelles (coordonnées bancaires, numéro de comptes, virements);</li>
                            <li>Informations liées à vos activités numériques (adresse IP, activité de navigation, géolocalisation)</li>
                            <li>Données liées à vos images, collectées dans le cadre de la vidéosurveillance</li>
                            <li>Données liées à votre utilisation de nos produits et services souscrits (données bancaires, financières et transactionnelles);</li>
                            <li>Données récoltées dans le cadre de nos échanges avec vous dans nos agences (compte-rendu
                                de rendez-vous), nos sites internet, nos applications, nos pages sur les réseaux sociaux, lors de
                                réunions, appels, chats, courriers électroniques, entretiens, conversations téléphoniques ;</li>
                            <li>Données Médicales (pour votre prise en charge médicale).</li>
                        </ul>
                        <h3>3. Les données à caractère personnel collectées de manière indirecte</h3>
                        <i>Les données collectées de manière indirectes sont :</i>
                        <p>Nous pouvons également être amenés à collecter des informations de personnes non clientes et
                            avec lesquelles nous n’avons pas de relation directe. Aussi, Afin de vérifier ou d’enrichir nos bases
                            de données, nous pouvons avoir accès à des données qui peuvent provenir de source autre que
                            vous ; entre autres :</p>
                        <ul>
                            <li>Un employeur;</li>
                            <li>Un membre de la famille;</li>
                            <li>Représentant légal (mandats/délégations de pouvoirs);</li>
                            <li>Actionnaire(s) de sociétés;</li>
                            <li>Représentant(s) d’une société légale (laquelle peut être cliente ou prestataire) ;</li>
                            <li>Un membre du personnel de nos prestataires de services et de nos partenaires commerciaux ;</li>
                            <li>Journaliste(s);</li>
                            <li>Contact(s) personnel(s).</li>
                            <li>Bases de données rendues publiquement accessibles par des tiers.</li>
                        </ul>
                        <p>
                            Nous ne vous demandons jamais de nous fournir des données personnelles relatives à votre origine
                            raciale ou ethnique, à vos opinions politiques, à vos convictions religieuses ou philosophiques, à
                            votre appartenance syndicale, à vos données génétiques ou à votre orientation sexuelle, à moins
                            que cela ne soit requis pour nous conformer à une obligation légale.
                        </p>
                        <h3>4. Que faisons-nous et à quelle fin collectons-nous vos données ?</h3>
                        <h4>1. Pour nous conformer à nos obligations légales ou réglementaires</h4>
                        <p>Nous utilisons vos données personnelles pour nous conformer à diverses obligations légales et
                            réglementaires auxquelles nous sommes tenus, et parmi lesquelles :</p>
                        <ul>
                            <li>La règlementation financière en application de laquelle nous mettons en place des mesures de
                                sécurité dans le but de prévenir les abus et la fraude ;</li>
                            <li>Détectons des transactions anormales ;</li>
                            <li>Surveillons et signalons les risques que nous pouvons encourir,</li>
                            <li>Enregistrons lorsque cela est nécessaire des appels téléphoniques, discussions, courriers Électroniques, etc.</li>
                            <li>Les réponses aux requêtes officielles d’autorités publiques ou judiciaires dûment autorisées ;</li>
                            <li>La lutte contre le blanchiment d’argent et le financement du terrorisme ;</li>
                        </ul>
                        <h4>2. Pour conclure un contrat avec vous ou pour prendre contact avec vous, à votre demande,
                            en vue de conclure un contrat </h4>
                        <ul>
                            <li>Nous utilisons vos données personnelles pour conclure et exécuter nos contrats, notamment afin de :</li>
                            <li>Vous fournir des informations relatives à nos produits et services ;</li>
                            <li>Vous assister et répondre à vos demandes;</li>
                            <li>Évaluer si nous pouvons vous proposer un produit ou un service et le cas échéant, sous quelles conditions</li>
                            <li>Fournir des produits ou services à nos clients entrepris dont vous êtes salarié ou client.</li>
                        </ul>


                        <h3>3. Pour la poursuite de nos intérêts légitimes</h3>
                        <p>Nous utilisons vos données personnelles dans le but de mettre en place et de développer nos produits ou
                            services, optimiser notre gestion du risque, assurer la défense de nos droits, et également afin de :</p>
                        <ul>
                            <li>Conserver la preuve d’opérations ou transactions ;</li>
                            <li>Gérer les technologies de l’information, en ce compris la gestion des infrastructures, la
                                continuité des services et la sécurité informatique ;</li>
                            <li>Personnaliser les produits ou services que nous vous proposons <br />
                                − En améliorant la qualité de nos produits ou services ;<br />
                                − En vous proposant des produits ou services correspondant à votre situation et à votre profil tel que nous le définissons. <br />
                            </li>
                            <li>
                                La gestion de vos comptes, dont l’administration de tout programme de fidélité ou de
                                récompense qui peut être lié à votre/vos compte(s).
                            </li>
                        </ul>


                        <h3>4. Pour respecter votre choix lorsque que nous vous avons demandé votre consentement pour un traitement spécifique</h3>
                        <p>
                            Dans certains cas, votre consentement nous est nécessaire pour pouvoir traiter vos données,
                            notamment : <br />
                            Lorsque les finalités décrites ci-dessus donnent lieu à une prise de décision automatisée produisant
                            des effets juridiques vous concernant ou vous affectant de manière significative, nous vous
                            informerons séparément de la logique sous-jacente de cette décision ainsi que de l’importance et
                            des conséquences liées à ce traitement ;
                        </p>
                        <ul>
                            <li>Si nous procédons à un traitement, à des fins autres que celles décrites dans la section 3, nous
                                vous en informerons et si nécessaire vous demanderons votre consentement.</li>
                            <li>Pour certaines interactions sur les réseaux sociaux, dans le but d’administrer des jeuxconcours ou autres opérations marketing de ce type.</li>
                        </ul>
                        <h3>5. Destinataires habilités à recevoir les données Personnelles</h3>
                        <p>Nous pouvons être amenés à partager vos données à caractère personnel avec :</p>
                        <ul>
                            <li>Notre groupe ;</li>
                            <li>Nos filiales ;</li>
                            <li>Nos prestataires ;</li>
                            <li>Nos mandataires indépendants, intermédiaires ou courtiers, partenaires bancaires ou
                                commerciaux avec lesquels les filiales sont en contact ;</li>
                            <li>Nos autorités judiciaires ou financières, agences d’État ou organismes publics, sur demande de
                                leur part et dans la limite de ce qui est autorisé par la réglementation ;</li>
                            <li>Nos professions réglementées tels que les avocats, notaires ou commissaires aux comptes.</li>
                        </ul>
                        <h3>6. Quelles mesures avons-nous mise en place pour la protection de vos
                            données ?</h3>
                        <p>Pour protéger vos données, nous mettons en place des mesures organisationnelles et techniques
                            appropriées dans le respect de la règlementation locale applicable ; ceci afin de préserver leur sécurité,
                            leur intégrité et notamment empêcher qu'elles soient déformées, endommagées ou communiquées à des
                            tiers non autorisés. Nous vous assurons par ailleurs que toutes les personnes qui interviennent dans le
                            traitement des données respectent l’ensemble des règles sur la protection des données à Caractère
                            Personnel et s'engagent à maintenir confidentielles les données traitées et garantir un niveau de sécurité
                            adapté aux risques.</p>

                        <h3>6.1.Quels sont vos droits ?</h3>
                        <h4>Section 1 : Droit à l’information</h4>
                        <p>Vous disposez d’un droit à l’information qui est de savoir si les données vous concernant font ou non
                            l’objet d’un traitement. Nous devons vous fournir au préalable et de façon claire toute information
                            pertinente concernant le traitement de vos données. <br />
                            Votre droit à l’information sur vos données porte sur la collecte, l’utilisation, la finalité, le(s)
                            responsable(s) de traitement, le(s) délai(s) de conservation, le(s) destinataire(s) de vos données et vos
                            droits sur le(s) traitement(s) de vos données. Vous devez être informé, sauf si cela a été fait au préalable
                            par nos soins, au plus tard lors de la collecte et ce, quels que soient les moyens et supports employés.
                            Ainsi, chaque fois qu’un traitement de vos données sera effectué pour une finalité différente de celle
                            pour laquelle les données ont été initialement collectées, nous nous réservons l’obligation de vous en
                            informer. </p>
                        <h4>Section 2 : Droit d’accès</h4>
                        <p>Vous disposez d’un droit d’accès aux données qui vous concernent et pouvez demander des copies.
                            Lorsque les demandes sont effectuées par voie électronique, les informations vous sont fournies sous
                            une forme électronique d’usage courant à moins que vous en demandiez qu’il en soit autrement.</p>
                        <h4>Section 3 : Droit de rectification</h4>
                        <p>
                            Vous bénéficiez d’un droit de rectification de vos données lorsque ces données sont inexactes. Compte
                            tenu des finalités des traitements, vous avez le droit d’obtenir que vos données incomplètes soient mis
                            à jour, en fournissant des déclarations complémentaires.
                            En cas de coresponsabilité ou de sous-traitance, nous transmettons lesdites données rectifiées au coresponsable ou au sous-traitant.
                        </p>
                        <h4>Section 4 : Droit à l’effacement (« droit à l’oubli »)</h4>
                        <p>Vous bénéficiez d’un droit à l’effacement de vos données dans l’une des conditions suivantes :<br />
                            - Vos données ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été
                            collectées ou traitées d’une manière ;<br />
                            - Vous retirez le consentement sur lequel est fondé le traitement, et il n’existe pas d’autre
                            fondement juridique au traitement ;<br />
                            - Vous vous opposez au traitement ;<br />
                            - Vos données ont fait l’objet d’un traitement illicite ;<br />
                            - Vos données doivent être effacées pour respecter une obligation légale
                            Nous nous devons de répondre à votre demande d’effacement de vos données et de vous le notifier
                        </p>
                        <h4>Section 5 : Exceptions ou droit à l’effacement</h4>
                        <p>Le droit à l’effacement ne pourra pas être exercé lorsque le traitement des données est nécessaire : <br />
                            - l’exercice de la liberté d’expression et de la liberté d’information ;<br />
                            - pour respecter une obligation légale que requiert le traitement ;<br />
                            - à des fins de statistiques, dans la mesure où l’effacement de vos données serait de nature à
                            rendre impossible ou à compromettre gravement les objectifs du traitement ; <br />
                            - à la constatation, à l’exercice ou à la défense de droits en justice
                        </p>
                        <h4>Section 6 : Droit à la limitation du traitement</h4>
                        <p>
                            Vous bénéficiez d’un droit à la limitation des traitements de vos données en imposant à Wizall de les
                            conserver, lorsque vous remplissez l’une de ces conditions : <br />
                            - vous contestez l’exactitude de vos données<br />
                            - le traitement est illicite et vous vous opposez à leur effacement et exigez à la place, la
                            limitation de leur utilisation ;<br />
                            - vous vous opposez au traitement et nous vérifions si nos motifs légitimes prévalent sur les
                            vôtres.<br />
                            - Wizall n’a plus besoin de vos données aux fins de traitements mais que celles-ci vous sont
                            encore nécessaires.
                        </p>
                        <h4>Section 7 : Droit à l’opposition du traitement</h4>
                        <p>
                            Vous avez le droit de vous opposer à tout moment au traitement de vos données, pour les raisons
                            suivantes : <br />
                            - risque d’atteinte à la vie privée ou à la réputation,<br />
                            - risque de sanctions disciplinaires <br />
                            - à des fins de prospection,<br />
                            - les traitements automatisés produisant des effets juridiques ou significatifs
                            Toutefois, lorsque le traitement automatisé ne produit pas d’effet juridique ni significatif pour vous,
                            Wizall pourra directement réaliser les traitements requis, en conformité avec les lois et réglementations
                            en vigueur.
                        </p>
                        <h4>Section 8 : Exception au droit à l’opposition</h4>
                        <p>Le Responsable de traitement peut refuser de mettre en œuvre votre droit d’opposition lorsqu’il établit
                            l’existence de motifs légitimes et impérieux pour des traitements qui prévalent sur vos intérêts ou vos
                            droits et vos libertés, ou pour la constatation, l’exercice ou la défense de droits en justice.</p>
                        <h4>Section 9 : Droit à la portabilité des données à caractère personnel </h4>
                        <p>
                            Vous bénéficiez d’un droit de récupération dans un format structuré, couramment utilisé et lisible par
                            machine, de vos données, lorsque ces dernières : <br />
                            - font l’objet d’un traitement automatisé ;<br />
                            - Ont été « fournies » au Responsable du traitement ;<br />
                            - Ont été obtenues sur la base d’un consentement exprès de la personne ou pour les besoins de
                            l’exécution d’un contrat.
                        </p>
                        <h3>6.2. Combien de temps conservons nous vos données à caractère personnel ?</h3>
                        <p>
                            Nous conservons vos données personnelles pour la durée nécessaire au respect des règlementations
                            applicables, ou pour une durée définie au regard de la finalité.  <br /><br />
                            Ainsi vos données personnelles sont conservées pendant toute la durée de la relation contractuelle et
                            pendant dix ans (durée réglementaire) après la fin de la relation contractuelle.<br /><br />
                            Les données collectées dans le cadre d’une prospection sont conservées durant deux ans.<br /><br />
                            Passé ces délais, vos données font l’objet d’une suppression tant à notre niveau qu’au niveau des
                            différents sous-traitants, éventuels
                        </p>

                        <h4>6.3. Comment exercer les droits clients ?</h4>
                        <p>Si vous avez des questions concernant le traitement de vos données personnelles, vous pouvez
                            contacter notre Correspondant à la Protection des Données aux adresses suivantes : <br /><br />
                            • Téléphone : +221 77 287 8004<br />
                            • Mail : mariama.diallo@wizall.com<br />
                            • Courrier : Sacré cœur 2 n°8653<br /><br />
                            Celle-ci se chargera de traiter votre demande.</p>
                    </Col>
                </Row>
            </div>

            <br />
        </div >
    );
}