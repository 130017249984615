import React from 'react';
import { Row, Col } from 'antd';

export default function Contrat() {
    return (
        <div className="contrat">
            <div className="container">
                <Row>
                    <Col lg={24} className="blc">
                        <p>
                        Contrat d’ouverture de compte de monnaie électronique (Personne Physique) 

                            Les présentes conditions générales d’utilisation (ci-après les « CGU ») sont conclues d'une part entre la société Wizall Sénégal, société à responsabilité limitée, au capital de 5 000 000 FCFA  FCFA dont le siège social est 29, avenue Pasteur – Dakar – Dakar, Sénégal, immatriculée au registre du commerce sous le numéro SN DKR 2015 B 19098 et NINEA n°005647302  ,  ci-après « Wizall» ou « Wizall Sénégal », et d’autre part le client souhaitant utiliser les services de Wizall en ouvrant un compte de porte-monnaie électronique à son nom, ci-après le «Client» ou le « Porteur».  <br></br>

                            Lors de l’ouverture du Compte, le client communique des informations personnelles nécessaires à l’enregistrement et l’activation de celui-ci : nom, prénom, numéro de téléphone, date de naissance, adresse, nature, numéro et copie de la pièce d’identité. <br></br>

                            Le client est informé que ces informations personnelles, et uniquement celles-ci, seront intégrées automatiquement au présent contrat aux fins de l’identifier, sous réserve que ses stipulations aient été préalablement et expressément acceptées par le Client au moment de la création du compte en cochant la case d’acceptation prévue à cet effet. <br></br>

                            Le Client dispose à tout moment de la capacité de télécharger son Contrat en cliquant sur l’onglet prévu à cet effet sur son Compte, puis de l’enregistrer et de l’imprimer. <br></br>

                            La monnaie Electronique servant de support aux offres Wizall est émise et gérée par BANQUE ATLANTIQUE SENEGAL Établissement de crédit autorisé à émettre de la monnaie électronique conformément à l’instruction N°008-05-2015 régissant les conditions et modalités d’exercice des activités des émetteurs de monnaie électronique dans les Etats Membres de l’Union Monétaires Ouest Africaine (UMOA), ci-après « l’Emetteur ». <br></br>

                            L’Emetteur recourt aux services de Wizall Sénégal en vue de la commercialisation des services liés, notamment : 

                            A la souscription des contrats d’utilisation avec la clientèle ; <br></br>
                            Aux chargements des unités de monnaie électronique ;<br></br>
                            Aux opérations de retrait d’espèces et de remboursement des unités de monnaie électronique ; <br></br>
                            Aux opérations de paiement. <br></br><br></br>
                            
                            <strong>0. Définitions </strong> <br></br>

                            BCEAO : Désigne la banque Centrale des Etats de l’Afrique de l’Ouest 
                            Compte de monnaie électronique ou Compte : Désigne le compte de monnaie électronique associé à tout Client. <br></br>

                            Conditions Tarifaires: désigne le document contenant les tarifs applicables au Porteur pour l’utilisation des fonctionnalités de son porte monnaie électronique 

                            Porteur ou Client : Désigne tout utilisateur des services Wizall détenteur d’un Compte de Monnaie électronique. <br></br>

                            Distributeur : Désigne la société Wizall Sénégal mandatée par l’Emetteur pour distribuer la Monnaie électronique, dans les conditions du présent Contrat. <br></br>

                            Marchands : désigne le réseau de fournisseurs de Wizall Sénégal proposant les Biens et/ou services auprès desquels le Porteur peut payer avec son Compte de Monnaie Electronique sous réserve qu’il dispose d’un solde d’UV suffisant sur son Compte. <br></br>

                            Monnaie Electronique : désigne la valeur monétaire représentant une créance, incorporée sous forme électronique contre remise de fonds de valeur égale, qui peut être utilisée pour effectuer des paiements à des personnes physiques ou morales autres que l’émetteur. L’unité de valeur de la monnaie électronique est appelée UV (Instruction BCEAO N°008-05-2015). <br></br>

                            Etablissement de monnaie électronique ou Emetteur : Désigne BANQUE ATLANTIQUE SENEGAL Etablissement de crédit autorisé à émettre de la monnaie électronique conformément à l’instruction N°008-05-2015 régissant les conditions et modalités d’exercice des activités des émetteurs de monnaie électronique dans les Etats Membres de l’Union Monétaires Ouest  Africaine (UMOA) 

                            Points de distribution/de Vente : désigne le réseau de partenaires, distributeurs des Services Wizall, disposant d’un ou de plusieurs point(s) de vente. <br></br>

                            Transaction ou Opération : Désigne toute opération effectuée par le Porteur sur son Compte de Monnaie Electronique 

                            U.V (Unité de Valeur Electronique) : désigne l’unité de compte de la monnaie électronique. <br></br>

                            Wizall Money : désigne la marque commerciale de Wizall Sénégal <br></br><br></br>

                            <strong>1. Objet  </strong> <br></br>

                            Le présent document a pour objet de déterminer les conditions et modalités selon lesquelles le Porteur ouvre et utilise le Compte de Monnaie électronique Wizall.  <br></br><br></br>

                           <strong>2. Ouverture du CME </strong> <br></br>

                            Accès & matériels <br></br>

                            L’inscription par le Client aux fins de création d’un compte de monnaie électronique Wizall se fait par l’application mobile de Wizall. La liste de ces Points de Distribution est disponible sur demande et peut être modifiée à tout moment et sans préavis par Wizall. <br></br>
                            

                            Pour détenir et utiliser un Compte de Monnaie Electronique, le Client doit préalablement s’enregistrer et ouvrir le Compte soit en téléchargeant l’application mobile Wizall sur son téléphone mobile soit auprès du réseau de Distributeurs agrées de Wizall Sénégal. <br></br>

                            Le Compte de Monnaie Electronique fonctionne exclusivement par le biais du site internet ou de l’application Wizall.  Par conséquent, le Porteur doit impérativement disposer : <br></br>

                            D’un numéro de téléphone 

                            D’un accès internet 

                            D’un volume suffisant de data internet pour pouvoir utiliser le Compte 

                            Ces conditions doivent être réunies pour ouvrir et utiliser un Compte de Monnaie Electronique Wizall. <br></br>

                            Mineurs <br></br>

                            Les services de Wizall sont destinés uniquement aux personnes dotées de la capacité juridique.. Pour les mineurs non émancipés ou les majeurs incapables, le Porteur présente obligatoirement une pièce d’identité  ainsi qu’une autorisation écrite en cours de validité des parents ou tuteurs.  <br></br>

                            Informations et/ou documents à fournir  <br></br>

                            Le Porteur se rend dans un des Points de distribution  et fournit impérativement les informations et les documents suivants : <br></br>

                            nom, prénom <br></br>

                            numéro de téléphone portable 

                            date de naissance ; nationalité <br></br>

                            adresse <br></br>

                            n°de la CNI sénégalaise ou du passeport <br></br>

                            copie de la CNI sénégalaise ou du passeport <br></br>

                            numéro de téléphone du Porteur  Pièces d’identité requise pour un étranger <br></br>

                            Activation du service et sécurité <br></br>

                            Une fois que la demande d’activation est faite, le Client reçoit sur son téléphone portable, au numéro indiqué, un SMS le notifiant de l’ouverture de son Compte de Monnaie Electronique. Ce SMS contient un code secret à 4 chiffres permettant de confirmer l’activation du Compte. Ce code secret doit être donné au Point de Distribution. Ce code est à usage unique. <br></br>

                            Par la suite et dans le cadre de l’utilisation des fonctionnalités et service du Compte de Monnaie Electronique, le Client dispose d’un code secret à 6 chiffres dès l’activation du service qu’il devra taper sur son clavier de téléphone avant chaque action afin de sécuriser les Transactions et de bien authentifier son auteur, le Porteur. Il pourra le modifier à tout moment. <br></br>

                            L’Emetteur est en droit de refuser l’ouverture et l’activation d’un Compte sans avoir à fournir de motifs au client. Néanmoins, le Client sera notifié du refus de l’Emetteur de lui ouvrir un compte. Aucune indemnité ne sera dû au Client dans ce cas par l’Emetteur. <br></br>

                            Par ailleurs, nonobstant ce qui est indiqué plus haut, l’Emetteur est en droit de requérir des informations et documents complémentaires sur le Client relatif notamment à son niveau d’authentification d’identité, s’il juge que cela est nécessaire pour des motifs qu’il est en droit de ne pas communiquer au Client.  <br></br>

                            Archivage  <br></br>

                            Wizall Sénégal informe le Porteur que, conformément à la règlementation en vigueur, toutes les relatives aux Operations sont conservées et archivées de façon sécurisée pendant une période de 10 ans, à compter de la fin de l’exercice au cours duquel ces Transactions ont été réalisées. <br></br>

                           
                            <strong>3. Limitations d’utilisation du Compte de Monnaie Electronique </strong> <br></br>

                            
                            Conformément à la règlementation, l’Emetteur peut ouvrir un Compte de Monnaie Electronique pour un montant total mensuel en monnaie électronique qui ne peut excéder deux cents mille (200 000) F CFA pour le Porteur. Ce plafonnement s’applique tant que le numéro et la copie de la pièce d’identité du Porteur ne sont pas enregistrés et validés.  <br></br>

                            Lorsque les informations requises sont complètes et permettent, selon l’appréciation de l’Emetteur, d’utiliser un Compte de Monnaie Electronique avec des encours plus importants, le Porteur est alors autorisé à détenir de la monnaie électronique jusqu’aux plafonds suivants : <br></br>

                            Avoirs maximum en monnaie électronique détenus par le Client : deux millions (2 000 000) F CFA <br></br>

                            Cumul maximum des rechargements en monnaie électronique effectué au cours d’un mois, par le Client : dix millions (10 000 000) F CFA <br></br>

                            Par ailleurs, le Porteur s’engage, lorsqu’il possède plusieurs instruments distincts émis par l’Emetteur à ce que le solde cumulé n’excède pas les montants visés ci-dessus, sous peine de voir sa responsabilité engagée. <br></br>
                            
                            <strong> 4. Conditions d’utilisation des services</strong> <br></br>

                            Le Compte de Monnaie Electronique est ouvert intuitu personae. Il est nominatif, personnel et en aucun cas cessible.  <br></br>

                            Le Porteur ne doit en aucun cas laisser une tierce personne utiliser le Compte, y compris le personnel de Wizall Sénégal, notamment en fournissant son code secret personnel pour accéder aux différents fonctionnalités et services. <br></br>

                            Tout manquement aux règles ci-avant énoncées entraine la responsabilité exclusive du Porteur sur toutes conséquences et préjudices éventuels liés à ces manquements. Aucune indemnité ne sera due dans ce cas par l’Emetteur, qui se réserve par ailleurs le droit de former tout recours judicaire ou autres à l’encontre du Porteur ayant failli à ses obligations. <br></br>

                            En cas de perte ou de vol du téléphone portable ou du code secret, le Client doit immédiatement en informer Wizall Sénégal qui prendra les dispositions qui s’imposent en ce cas, et notamment en réinitialisant un nouveau mot de passe pour le Client.  <br></br>

                            Tant que le Client n’a pas notifié la perte, fraude et/ou vol ou autres cas similaires  à l’Emetteur, le client sera seule responsable des conséquences en découlant. <br></br>

                            Par ailleurs, toute utilisation du Compte de Monnaie Electronique en dehors des fonctionnalités et conditions autorisées se fait sous l’entière responsabilité du Porteur. <br></br>

                            Le Porteur s’engage à ne pas utiliser les services Wizall dans le but de commettre une infraction à toute loi ou réglementation applicable. <br></br>

                            Le Porteur doit informer Wizall Sénégal de ses changements d’état civil, de coordonnées. <br></br>

                            Pour toute transaction réalisée, un reçu électronique et papier sera produit conformément à la réglementation. <br></br>

                            Pour toute demande d’informations, réclamations, le Client pourra contacter le service client WIZALL au numéro de téléphone suivant : <br></br>

                            +221 33 864 99 00 (lundi au vendredi de 8H à 20H et samedi, dimanche et jours fériés de 9H30 à 17H30) <br></br>

                            Par mail : contact@wizall.com <br></br> <br></br>

                            <strong>5. Fonctionnalités de l’utilisation des UV  </strong> <br></br>

                            L’unité de valeur de cette Monnaie Electronique est l’UV, strictement  équivalent en termes de valeur au Franc CFA (1 UV égal 1 Franc). 

                            Les UV sont chargées sur le Compte de Monnaie Electronique en contrepartie de Francs CFA payés par le Porteur, auprès du réseau de distributeurs de Wizall Sénégal. 

                            Le solde du Compte de Monnaie Electronique est intégralement préfinancé par le Porteur. De même, le Compte de Monnaie Electronique ne peut en aucun cas présenter un solde débiteur, ni offrir ou se voir offrir des services assimilés bancaires de crédit. Le Porteur n’est en mesure d’effectuer que les Transactions pour lesquelles il dispose à un instant t d’un solde d’UV disponible et suffisant, y compris pour le paiement des frais et commissions des Transactions le cas échéant, sur son Compte de Monnaie Electronique. 

                            Une fois que le compte de monnaie électronique est ouvert à son nom, le Client est en mesure d’effectuer les opérations suivantes: 

                            Chargement/rechargement en UV auprès d’un distributeur (cash in) 

                            Envoi d’UV de son compte vers un autre compte de monnaie électronique, sous réserve de disposer d’une quantité d’UV suffisante  

                            Réception d’un envoi d’UV 

                            Paiement de biens et services auprès des Marchands sous réserve de disposer d’une quantité d’UV suffisante 

                            Retrait d’espèces (décaissement pour Wizall) sous réserve de disposer d’une quantité d’UV suffisante (cash out) 

                            Remboursement des UV <br></br> <br></br>

                            

                            <strong>6. Respect des règles relatives à la lutte contre le blanchiment de capitaux et financement du terrorisme </strong> <br></br>

                            L’Emetteur a reçu un agrément  afin d’exercer l’activité d’émetteur de monnaie électronique conformément à la règlementation BCEAO.  <br></br>

                            Dans le cadre de la règlementation visant à lutter contre le blanchiment de capitaux et le financement du terrorisme (Directive n°02/2015/CM/UEMOA du 2 juillet 2015), l’Emetteur a l’obligation de vérifier la source, l’objet et la destination de toute transaction, dans la limite des seuils qui sont prévus par l’instruction  BCEAO n°008-05-2015 régissant les conditions et modalités d’exercice des activités des émetteurs de monnaie électronique dans les Etats membres de l’Union Monétaire Ouest Africaine. A ce titre, les informations, y compris les informations personnelles, les conversations avec le Service Clients et les Transactions seront enregistrées et stockées pendant une période de dix  (10) ans à compter de la date de fermeture du compte de monnaie électronique. 

                            Les conditions sont notamment celles indiquées dans les présentes, ce que le Client déclare expressément accepter.   

                            En cas de constat d’Opération suspecte par l’Emetteur, celui-ci peut être amené à transmettre des informations à la Cellule Nationale de Traitement des Informations Financières (CENTIF) - (Directive n°02/2015/CM/UEMOA du 2 juillet 2015). 

                            Wizall Sénégal est également tenu au même titre que l’Emetteur au respect de la réglementation en vigueur en matière blanchiment de capitaux et de financement du terrorisme. A cet égard, Wizall Sénégal est tenue d’informer BANQUE ATLANTIQUE SENEGAL de toutes transactions suspectes en lien avec la monnaie électronique et dont elle aurait eu connaissance. 

                            
                            <br></br><br></br><strong>7. Compte de cantonnement  </strong> <br></br>

                            
                         
                            La contrevaleur de la monnaie électronique utilisée dans le cadre des Transactions est déposée dans un compte de cantonnement auprès d’un établissement bancaire, conformément à la règlementation de la BCEAO sur l’émission de monnaie électronique, par l’Emetteur code monnaie électronique. 

                            Ainsi, l’Emetteur ne peut en aucun cas disposer de ces sommes en dehors de l’utilisation qui doit en être faite dans le cadre des services proposés. 

                            <br></br><br></br><strong>8. Conditions tarifaires  </strong> <br></br>

                            

                            Les conditions tarifaires sont affichées sur tous les Points de Distribution. <br></br>

                            Elles sont également disponibles sur demande aux coordonnées indiquées plus haut. <br></br>

                            Les prix sont affichés et payés TTC par le Client. Le prix payé (commissions et principal) par le Client en contrepartie de l’utilisation de son Compte de Monnaie Electronique est directement prélevé sur son Compte au moment où il réalise la Transaction, sauf dans le cas du rechargement auprès du Point de Distribution pour lequel le paiement s’effectue en numéraire auprès du sous-distributeur (« cash-in ») 

                            <br></br><br></br><strong>9. Durée </strong> <br></br>

                            Le compte de monnaie électronique est ouvert pour une durée indéterminée. Le Client pourra résilier le contrat à  sa convenance. 

                            <br></br><br></br><strong>10. Remboursement </strong> <br></br>

                            En cas de résiliation, le Client pourra recevoir selon les modalités de paiement de son choix, la contrevaleur fiduciaire des UV non utilisées au crédit de son Compte au moment de la demande de résiliation, après comptabilisation des opérations en cours. Le remboursement sera effectif au plus tard dans les trois jours ouvrés. 

                            Ce remboursement des unités de monnaie électronique non utilisées, s’effectue à la valeur nominale en FCFA des unités de monnaie électronique disponibles. 

                            Le remboursement peut être effectué sur l’ensemble du réseau de distribution de Wizall Sénégal dans un délai maximum de 3 (trois) jours à compter de la demande faite par le Porteur.  

                            Wizall Sénégal, agissant au nom et pour le compte de l’EME, est responsable du remboursement des UV. 

                            Modalités : espèces en FCFA, chèque, virement bancaire, selon la préférence exprimée par le Porteur. 

                            <br></br><br></br><strong>11. Suspension / résiliation du compte de monnaie électronique  </strong> <br></br>

                            Chacune des Parties peut de plein droit et sans avoir à justifier d’un motif, résilier le lien contractuel qui les lie par courrier à l’adresse suivante:  

                            Wizall Sénégal 

                            - 29, avenue Pasteur - Dakar - Sénégal  

                            ou par courrier contresigné remis en main propre auprès de Wizall Sénégal. 

                            Dans tous les cas de résiliation du compte, tout crédit dû au Client, s’il en a un, lui sera remboursé dans un délai maximum de trois jours ouvré à compter de la date de résiliation. 

                            L’Emetteur pourra demander à Wizall de procéder à la suspension ou la résiliation du compte du client sans prévenir le Client, si les circonstances l’exigent, notamment en cas de demande des autorités bancaires et judiciaires. En cas de solde positif, les fonds seront rapatriés sur un compte séquestre. 

                            De même, Wizall pourra de sa propre initiative suspendre ou résilier le Compte en cas de soupçon d’opérations ou de comportements suspects et de fraude, conformément à la règlementation en vigueur. 

                            Wizall pourra procéder à la résiliation du compte en cas de décès du client sur demande des ayant-droits. En cas de solde positif, le remboursement du montant disponible sur le compte de monnaie électronique pourra être versé aux ayant-droits après que les opérations de vérifications aient été réalisées. 

                            La fermeture du Compte sera effective dans un délai de 3 jours ouvrés suivant la réception de la demande de résiliation.  

                            Aucune indemnité ne pourra être réclamée à l’Emetteur en cas de résiliation.  

                            

                            <br></br><br></br><strong>12.  Force majeure  </strong> <br></br>

                            Si l’Emetteur et/ou Wizall Sénégal ne pouvait pas exécuter normalement l’une quelconque de ses obligations en raison d’un fait aléatoire qu’elle ne pourrait pas maîtriser, elle fera ses meilleurs efforts pour faire face à la situation. 

                            <br></br><br></br><strong>13.  Responsabilité   </strong> <br></br>

                            Chaque Partie est responsable de tout dommage qu’elle cause directement à l’autre Partie ou à des tiers du fait de l’exécution du contrat dans les conditions du droit commun et de la jurisprudence. 

                            L’Emetteur ne peut en aucun cas être tenu responsable d’un quelconque préjudice subi par le Client notamment concernant la qualité, le dysfonctionnement, la défectuosité des biens et/ou services que le Client aurait acquis auprès des Marchands.  

                            Le Client doit vérifier l’exactitude des données du destinataire, ainsi que le montant. En cas d’erreur, l’Emetteur et Wizall Sénégal ne pourront être tenus responsables. 

                            <br></br><br></br><strong>14. Propriété intellectuelle </strong> <br></br>

                            Tous les droits et éléments de propriété intellectuelle du Client sont et restent sa propriété exclusive. Dans le cas contraire, il déclare disposer de tous les droits d’utilisation requis par la règlementation en vigueur. 

                            Le Client n'est pas autorisé à reproduire, exploiter, rediffuser, ou utiliser à quel que titre que ce soit, même partiellement, ces éléments et/ou droits qu'ils soient logiciels, visuels ou sonores. Le client s’interdit de faire référence et d’utiliser les raisons sociales ou les marques et logos de l’Emetteur ou de son groupe sans autorisation préalable, expresse et écrite de ce dernier. 

                            <br></br><br></br><strong>15.  Données à caractère personnel  </strong> <br></br>

                            Wizall Sénégal et l’Emetteur s’engagent à se conformer à la législation et aux règles applicables en matière de données à caractère personnel. 

                            La collecte de données à caractère personnel est indispensable à l’utilisation et à la gestion du Compte de Monnaie Electronique Wizall. 

                            Ces données pourront être utilisées à des fins marketing et dans le cadre d’offres promotionnels. 

                            Ces données sont collectées auprès du Client au moment de la réalisation de la première Transaction. Le Client reconnait que certaines informations à caractère personnel doivent obligatoirement être transmises aux autorités administratives compétentes notamment dans le cadre de la règlementation prudentielle relative aux établissements de monnaie électronique. 

                            Tout refus entrainera la suspension ou la suppression du compte et/ou le blocage des transactions en cours du Client. 

                            Wizall dispose de moyens informatiques destinés à gérer plus facilement la base de données Client et Bénéficiaire dans le cadre de la bonne exécution des Services Wizall ci-dessus décrits. 

                            Les informations enregistrées sont réservées à l’usage des services concernés et ne peuvent être communiquées qu’aux personnes directement impliquées dans la gestion des services Wizall et peuvent être communiqués à des partenaires commerciaux dans le cadre d’offres promotionnels. 

                            Sauf avis contraire du client, Ces données pourront être communiquées, sous réserve d’anonymisation, et exploitées après anonymisation, par des tiers, notamment à des cabinets d'étude de marché et instituts de sondage et exclusivement à des fins d'étude et d'analyse, 

                            Sauf avis contraire du Client, ces informations pourront être exploitées et communiquées à des sociétés dans le cadre d'opérations commerciales conjointes ou non, notamment pour des opérations de marketing direct, À tout moment, le client peut faire valoir son droit d’opposition. <br></br>

                            CONFORMÉMENT À LA LOI N° RD 2014-0035/SP DU 24 JUILLET 2008 RELATIVE À LA PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL, IL EST RAPPELÉ AU BÉNÉFICIAIRE ET AU CLIENT QU’ILS DISPOSENT D’UN DROIT D’ACCÈS, DE MODIFICATION, DE RECTIFICATION ET DE SUPPRESSION DES DONNÉES QUI LES CONCERNENT, EN S’ADRESSANT À WIZALL DE LA FAÇON SUIVANTE : <br></br>

                            PAR ADRESSE POSTALE :  <br></br>

                            WIZALL SENEGAL <br></br>

                            ADRESSE : 29, AVENUE PASTEUR – DAKAR - SENEGAL <br></br>

                            MAIL : CONTACT@WIZALL.COM <br></br>

                            TEL : 33 864 99 00 <br></br>

                            NOM DU RESPONSABLE DE TRAITEMENT : SEBASTIEN VETTER <br></br>

                            WIZALL SÉNÉGAL, 29 AVENUE PASTEUR - DAKAR <br></br>

                            PAR MAIL :SEBATIEN.VETTER@WIZALL.COM <br></br>

                            La création, la collecte et le traitement des données personnelles dans le cadre des activités de Wizall ont été autorisés au Sénégal par la Commission de Protection des Données Personnelles (CDP) par délibération de la Commission des Données Personnelles (CDP) n° 2015-00140/CDP. <br></br>

                            Les démarches administratives auprès de la CDP sont effectuées et relèvent de la responsabilité de Wizall Sénégal. <br></br>

                            Dans le cadre des obligations légales en la matière, l’Emetteur peut être amenée à communiquer les informations relatives au Client à la demande des Autorités judiciaires ou Administratives compétentes. La communication se fera conformément aux dispositions légales applicables en la matière. <br></br>

                            

                           <br></br><strong>16. Assurances  </strong> <br></br>

                            Wizall Sénégal déclare avoir souscrit une assurance Responsabilité Civile le garantissant dans l’exercice de sa profession pour les seuls dommages qui pourraient lui être imputés du fait de ses interventions. 

                            <br></br><br></br><strong> 17 – Litiges  </strong> <br></br>

                            Les CGU sont soumises aux Lois et règlements en vigueur au Sénégal. 

                            Les parties s’efforceront de résoudre amiablement leur différend. A défaut, tout litige relatif au Contrat, notamment à son existence, à sa validité ou à sa résiliation, est soumis aux Tribunaux de Dakar.  
                        </p>
                    </Col>
                </Row>
            </div>

            <br />
        </div>
    );
}