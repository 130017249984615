import React, { Component } from "react";
import axios from "axios";
import { Row, Col } from "antd";
import Banner5 from "../img/banner5.png";
import Footer from "../../Components/Footer/index";
import Header from "../../Components/Header/index";

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
    };
  }

  componentDidMount() {
    this.getAgent();
  }

  getAgent = () => {
    axios({
      method: "GET",
      url: `https://www.wizallmoney.com/api/agent/map`,
    }).then((res) => {
      this.setState({
        agents: res.data,
      });
      this.callScript();
    });
  };

  callScript() {
    this.loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBrcg41pPk-f_KKtW6zs6BJaVzRoJ-uI6g&callback=initMap"
    );
    window.initMap = this.initMap;
  }

  markerColor = (color) => {
    if (color === "E10132") {
      return require(`./icons/e10132.png`);
    }
    if (color === "23B8CD") {
      return require(`./icons/23b8cd.png`);
    }
    if (color === "E86C18") {
      return require(`./icons/e86c18.png`);
    }
    if (color === "328F36") {
      return require(`./icons/328f36.png`);
    }
    if (color === "3360AB") {
      return require(`./icons/3360ab.png`);
    }
    if (color === "DA2122") {
      return require(`./icons/da2122.png`);
    }
    return require(`./icons/23b8cd.png`);
  };

  initMap = () => {
    var map = "";
    map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 5,
      center: { lat: 16.0271992, lng: -16.5040184 },
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
    });

    const infoWin = new window.google.maps.InfoWindow();
    const markers = this.state.agents.map((location, i) => {
      const marker = new window.google.maps.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        icon: this.markerColor(location.couleur.substring(1)),
      });
      window.google.maps.event.addListener(marker, "click", function () {
        const html = `<strong>${location.partenaire}</strong> <br/><br/>${location.telephone}`;
        infoWin.setContent(html);
        infoWin.open(map, marker);
      });
      return marker;
    });
    const markerCluster = new window.MarkerClusterer(map, markers, {
      minimumClusterSize: 3,
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });
    return markerCluster;
  };

  loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
  }

  render() {
    return (
      <>
        <Header />
        <div className="reseau-agent fadeIn wow">
          <div className="section-1">
            <img className="d-block w-100" alt="" src={Banner5} />
          </div>
          <div className="section-2">
            <div className="container">
              <Row>
                <Col lg={7}>
                  <di>
                    <h4>
                      Retrouvez tous nos services dans notre large réseau
                      d'agents au Sénégal, Côte d'Ivoire, Burkina Faso et Mali.
                    </h4>
                    <br />
                    <div className="enrolement-agent">
                      <a
                        href="https://www.appsheet.com/start/e2f08e02-a3d9-47b9-9290-2570f6a99735"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                      >
                        Devenir agent
                      </a>
                    </div>
                  </di>
                </Col>

                <Col lg={17}>
                  <div id="content">
                    <div id="map"></div>
                  </div>
                  <br />
                  <br />
                  <h4>
                    Si vous souhaitez devenir agent Wizall Money, appuyez sur le
                    bouton DEVENIR AGENT et remplissez le formulaire. Vous serez
                    recontacté par nos services (et vous pouvez aussi nous
                    contacter via les infos en bas de page).
                  </h4>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default MapContainer;
