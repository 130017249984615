import React, { useState } from 'react';
import { Row } from 'antd';
import "./styles/style.scss";
import axios from 'axios';


export default function DgpsnPage() {
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")

    const handleMail = () => {
        setIsLoading(true)
        axios.post(`https://www.wizallmoney.com/api/contact`, {
            name: "DGPSN",
            email: "test-dgpsn@wizall.com",
            subject: "Le Président a cliqué",
            message: "Le Président a cliqué",
            phone_number: "338649901",
            country: "sn"
        })
            .then(() => {
                setIsSuccess(true)
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log("error:===>", error);
                setError(error.toString())
            });
    }


    return (
        <div className="dgpsn-page">
            <div className="container">
                <Row>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <img src='assets/img/dgpsn.png' className='dgpsn-logo' alt='wizall' />
                        <img src='assets/img/logo.png' className='wizall-logo' alt='wizall' />
                    </div>
                    <br /><br />
                    {isSuccess ?
                        <div className='blc-second'>
                            <h1> Merci, les paiements ont été effectués avec succès.</h1>
                            <img src='assets/img/success-icon.png' alt='wizall' />
                        </div>
                        :
                        <div className='blc-one'>
                            <h3>Le Président Macky Sall et le gouvernement du Sénégal soutiennent la résilience des ménages face aux effets de Covid19 et aux conséquences de crise internationale.
                                <br /><br />
                                <span>Appuyez sur le bouton suivant pour valider les paiements :</span>
                            </h3>
                            <br /><br />
                            <center>
                                <div onClick={() => handleMail()}>
                                    <img src='assets/img/success-icon.png' alt='wizall' width="15%" height="100%" />
                                </div>
                            </center>
                            {isLoading && <span className='loader'>Chargement...</span>}
                            {error && <span className='error-message'>{error}</span>}
                        </div>
                    }
                </Row>
            </div>
            <br />
        </div>
    );
}