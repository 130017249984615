import React from 'react';
import { Row, Col } from 'antd';
import Banner4 from '../img/Banner4.png'
import Billetage_afrique from '../img/billetage_afrique.png'
import Footer from '../../Components/Footer/index';
import Header from '../../Components/Header/index';


export default function wizallMoneyPro() {
    return (
        <>
            <Header />
            <div className="wizallMoneyPro">
                <div className="section-1">
                    <img alt="" src={Banner4} />
                </div>
                <div className="section-2">
                    <div className="container">
                        <Row>
                            <Col lg={14}>
                                <p>Wizall Money <strong> est la meilleure alternative au billetage en Afrique.</strong> </p>
                                <p>A travers une interface web sécurisée, fiable et disponible 24/24 et 7/7, vous pouvez effectuer vos transferts en toute sécurité. Cette solution est compatible avec tous les numéros de téléphone et tous les opérateurs.</p>
                                <p>Wizall Money Pro, c’est :</p>
                                <ul>
                                    <li><strong> Effecteur des Paiements de salaires, subventions de bourses, perdiem…</strong> Si votre collaborateur à un compte Wizall Money, il recevra les fonds sur son compte, s’il n’a pas de compte Wizall Money, il recevra un code de retrait par SMS. Tous les retraits sont entièrement gratuit avec Wizall Money.</li>
                                    <li><strong>Effectuer des paiements de masse</strong>, vous pouvez payer plusieurs milliers de personnes en quelques clics </li>
                                    <li><strong>Collecte,</strong> encaissement de mes factures via Wizall Money</li>
                                    <li><strong>Paiement des factures, Achat de crédit et Recharge de carte</strong> (Pétrolière, autoroute…)</li>
                                </ul>
                            </Col>

                            <Col lg={10}>
                                <img src={Billetage_afrique} alt="" />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}