import React from 'react';
import { Row, Col } from 'antd';


export default function Privacy() {
    return (
        <div className="mention-legales">
            <div className="container">
                <Row>
                    <Col lg={24} className="blc">
                        <h1>Politique de confidentialité pour Wizall Money</h1>
                        <p>
                            <span>Dernière mise à jour : 02 avril 2024</span> <br />
                            Cette politique de confidentialité décrit la manière dont Wizall accède, recueille, utilise et communique vos données sur notre application mobile et notre plateforme en ligne. Elle vous informe de vos droits à la vie privée et de la manière dont la loi vous protège.
                            <br />
                            Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant le Service, vous acceptez la collecte et l'utilisation d'informations conformément à la présente politique de confidentialité.
                        </p>
                        <h2>Interprétation et définitions</h2>
                        <h3>Interprétation</h3>
                        <p>Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes. Les définitions suivantes auront la même signification qu’elles apparaissent au singulier ou au pluriel.</p>
                        <h2>Définitions</h2>
                        <p>
                            Aux fins de cette politique de confidentialité :
                            <ul>
                                <li>Compte désigne un compte unique créé pour vous permettre d'accéder à notre service ou à des parties de notre service.</li>
                                <li>Société affiliée désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec un parti, où « contrôle » désigne la propriété de 50 % ou plus des actions, participations ou autres titres donnant droit à voter pour l'élection d'administrateurs ou d'autres autorités de gestion.</li>
                                <li>L'application fait référence à Wizall Money, le logiciel fourni par la Société Wizall Services</li>
                                <li>La Société fait référence à Wizall Services, Immeuble Yaye Fatou Dieng, Rue 1 x Rue – Dakar Sénégal</li>
                                <li>Les pays : Sénégal, Côte d'Ivoire, Mali et Burkina Faso</li>
                                <li>Appareil désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.</li>
                                <li>Les données personnelles sont toute information relative à une personne identifiée ou identifiable.</li>
                                <li>Service fait référence à l’Application.</li>
                                <li>Prestataire désigne toute personne physique ou morale qui traite les données pour le compte de la Société. Il fait référence à des sociétés tierces ou à des personnes employées par la Société pour faciliter le Service, pour fournir le Service au nom de la Société, pour exécuter des services liés au Service ou pour aider la Société à analyser la manière dont le Service est utilisé.</li>
                                <li>Les Données d'Utilisation font référence aux données collectées automatiquement, soit générées par l'utilisation du Service, soit à partir de l'infrastructure du Service elle-même (par exemple, la durée d'une visite d'une page).</li>
                                <li>Le Client : désigne la personne qui accède ou utilise le Service, ou la société ou toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon le cas.</li>
                            </ul>
                        </p>

                        <h2>Collecte et utilisation de vos données personnelles</h2>
                        <h3>Types de données collectées</h3>
                        <h4>Données personnelles</h4>
                        <p>Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnelles identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations personnelles identifiables peuvent inclure, sans s’y limiter :
                        </p>
                        <ul>
                            <li>Adresse e-mail</li>
                            <li>Prénom et nom</li>
                            <li>Numéro de téléphone</li>
                            <li>Adresse, État, Région, Code postal, Ville</li>
                            <li>Des données d'utilisation</li>
                            <li>Copie de l’ID (passeport ou CNI)</li>
                        </ul>
                        <p>Ces données personnelles sont collectées afin d’être en conformité avec la réglementation lors de l’ouverture de votre compte.</p>

                        <h4>Des données d'utilisation</h4>
                        <p>
                            Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.

                            <br /><br />    L'application WIZALL Money collecte des informations concernant l'utilisation de l'application (heure de connexion, version de l'application, résolution d'écran de votre mobile, rubriques consultées, etc.) et nous envoie ces informations pour des analyses statistiques afin d'améliorer le service qui vous est proposé.

                            <br /><br />La collecte de ces informations nous permet également d'améliorer la pertinence des messages de notification envoyés par le service. Toutes les informations collectées sont entièrement anonymes et ne sont en aucun cas associées à l'utilisateur.

                            <br /><br />Ces informations sont collectées et traitées en conformité avec les lois et règlements en vigueur. Elles ne sont pas utilisées pour d'autres finalités que celle d'améliorer le service et la pertinence des messages de notification envoyés.

                            <br />Aucune donnée personnelle n'est collectée.

                            <br /><br /> Lorsque vous accédez au Service via le web ou via un appareil mobile, nous pouvons collecter automatiquement certaines informations, notamment, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil et d'autres données de diagnostic.
                        </p>


                        <h4>Informations collectées lors de l'utilisation de l'application</h4>
                        <p>Lors de l'utilisation de notre application, afin de fournir des fonctionnalités de notre application, nous pouvons collecter, avec votre autorisation préalable:</p>
                        <ul>
                            <li>Votre localisation, pour faciliter la recherche de nos agents (pour effectuer des dépôts et des retraits) situés à proximité de votre emplacement.</li>
                            <li>Les informations de votre répertoire téléphonique (liste de contacts), exclusivement utilisées pour vous permettre de choisir le numéro de téléphone de votre bénéficiaire, afin d'éviter les erreurs lors des transferts d'argent directs à vos contacts

                                <br /><br />Nous collectons uniquement le numéro de téléphone de votre contact afin de pouvoir identifier son numéro de compte de monnaie électronique dans notre système ou lui envoyer un code de retrait par SMS.

                                <br /><br />Nous tenons à souligner que les données de votre liste de contacts ne sont ni stockées ni enregistrées sur nos serveurs.

                                <br /><br />Si vous ne souhaitez pas utiliser cette fonctionnalité, vous pouvez refuser à l'application WIZALL l'accès à vos contacts. Cela n'entravera pas l'utilisation de l'application.

                                <br /><br />Vous pouvez activer ou désactiver l'accès à ces informations à tout moment, via les paramètres de votre appareil.</li>
                            <li>Images et autres informations provenant de l'appareil photo et de la photothèque de votre appareil, afin de vous permettre de télécharger votre carte d’identité ou passeport, ainsi que de prendre une photo de vous-même. Ces informations sont obligatoires pour l’ouverture d’un compte de mobile money – Wizall Money.</li>
                        </ul>
                        <br />
                        <p>En ce qui concerne les images et autres informations provenant de l'appareil photo et de la galerie de votre appareil, elles sont collectées dans deux cas de figure :</p>

                        <strong>a. La prise d’une photo en portrait du client</strong>
                        <p>
                            Cette collecte s’inscrit dans le processus d’auto-enregistrement des données du client souhaitant ouvrir un compte de monnaie électronique Wizall Money. Ainsi, dans notre parcours client, l’application présente un champ à compléter qui est la prise d’une photo par le client en portrait avant l’activation de son compte. Ce champ est obligatoire et la photo prise par le client lui-même.

                            <br /><br /> La finalité de collecte de cette donnée s’inscrit dans le cadre d’actions visant à réduire les risques de fraude.

                            <br /><br />En effet, cette donnée va permettre aux équipes en charge de la validation des comptes de bien vérifier que la personne qui a activé son compte est bien la même que celle indiquée sur la pièce d’identité, en procédant à la vérification croisée avec la photo prise au moment de l’activation.

                            <br /><br />Notre objectif principal est ainsi de protéger au mieux les intérêts de nos clients et leur argent déposé sur leur compte.

                            <br /><br />Cette donnée complémentaire est collectée, traitée, utilisée, conservée et détruite dans les mêmes conditions que les données initialement collectées.
                        </p>
                        <br />

                        <strong>b. Paiement via QR Code</strong>
                        <p>
                            Lors d'un paiement via QR code, vous pouvez scanner le QR code d'un commerçant ou d'un utilisateur pour plus de rapidité et pour éviter les erreurs.

                            <br />Toutes ces dispositions visent à améliorer votre expérience d'utilisation, basées sur les retours des utilisateurs de l'application WIZALL.

                            <br /> Utilisation de vos données personnelles

                            <br />Wizall peut utiliser les Données Personnelles aux fins suivantes :
                            <ul>
                                <li>Pour fournir et maintenir notre service, y compris pour surveiller l'utilisation de notre service.</li>
                                <li>Cela inclut l’utilisation des données pour : créer/mettre à jour des comptes, traiter les paiements et les réceptions de monnaie électronique, faciliter la facturation, dépanner les bugs logiciels et les problèmes opérationnels, et effectuer d’autres opérations nécessaires au maintien de nos services.</li>
                                <li>Pour gérer votre Compte : pour votre inscription en tant qu'utilisateur du Service. Les données personnelles que vous fournissez peuvent vous donner accès à différentes fonctionnalités du service qui sont à votre disposition en tant qu'utilisateur enregistré.</li>
                                <li>Pour l'exécution d'un contrat : l'élaboration, le respect et l'exécution du contrat d'achat des produits, articles ou services que vous avez achetés ou de tout autre contrat avec nous via le Service.</li>
                                <li>Pour vous contacter : par e-mail, appels téléphoniques, SMS ou autres formes de communication électronique équivalentes, telles que les notifications push d'une application mobile concernant les mises à jour ou les communications informatives liées aux fonctionnalités, produits ou services souscrits, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.</li>
                                <li>Pour vous fournir des actualités, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons et similaires à ceux que vous avez déjà achetés ou sur lesquels vous avez demandé des renseignements, à moins que vous ayez choisi de ne pas recevoir de telles informations.</li>
                                <li>Pour gérer vos demandes : Pour répondre et gérer vos demandes auprès de nous.</li>
                                <li>Pour les transferts d'entreprise : nous pouvons utiliser vos informations pour évaluer ou procéder à une fusion, une cession, une restructuration, une réorganisation, une dissolution ou toute autre vente ou transfert de tout ou partie de nos actifs, que ce soit dans le cadre d'une activité ou dans le cadre d'une faillite,

                                    d'une liquidation, ou une procédure similaire, dans laquelle les données personnelles que nous détenons sur les utilisateurs de nos services font partie des actifs transférés.</li>
                                <li>À d'autres fins : nous pouvons utiliser vos informations à d'autres fins, telles que l'analyse de données, l'identification des tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et l'évaluation et l'amélioration de notre service, de nos produits, de nos services, de notre marketing et de votre expérience.</li>
                            </ul>
                            <br /><br />Nous pouvons partager vos informations personnelles dans les situations suivantes :

                            <ul>
                                <li>Avec les fournisseurs de services : nous pouvons partager vos informations personnelles avec des fournisseurs de services pour surveiller et analyser l'utilisation de notre service et pour vous contacter.</li>
                                <li>Avec les autorités gouvernementales et autorités chargées de l’application de la loi : nous pouvons recevoir des informations de la part des autorités gouvernementales et des autorités chargées de l’application de la loi afin de détecter, de prévenir, et d’enquêter sur des fraudes ou d’autres activités suspectes et de nous conformer aux lois et aux réglementations applicables.</li>
                                <li>Pour les transferts d'entreprise : nous pouvons partager ou transférer vos informations personnelles dans le cadre de ou pendant les négociations de toute fusion, vente des actifs de la société, financement ou acquisition de tout ou partie de notre entreprise à une autre société.</li>
                                <li>Avec les affiliés : nous pouvons partager vos informations avec nos affiliés, auquel nous demanderons de respecter cette politique de confidentialité. Les sociétés affiliées comprennent notre société mère et toutes autres filiales, partenaires de coentreprise ou autres sociétés que nous contrôlons ou qui sont sous contrôle commun avec nous.</li>
                                <li>Avec des partenaires : nous pouvons partager vos informations avec nos partenaires commerciaux pour vous proposer certains produits, services ou promotions et mieux comprendre nos utilisateurs.</li>
                                <li>Avec d'autres utilisateurs : Nous partageons des données personnelles, y compris le numéro de téléphone et les noms des clients (expurgés dans la mesure du possible), avec d’autres utilisateurs lors de l’envoi ou de la réception de monnaie électronique ou de l’émission de relevés contenant des informations telles que l’historique des transactions.</li>
                                <li>Avec votre consentement : Nous pouvons divulguer vos informations personnelles à toute autre fin avec votre consentement.</li>
                            </ul>
                            En cas de transfert des données personnelles à des tiers, nous leur demanderons d’accepter les mesures nécessaires pour protéger les données, et de continuer à respecter les droits légaux des utilisateurs conformément aux termes du présent Politique de Confidentialité et aux lois applicables dans le pays de l’utilisateur.

                            <h4>Conservation de vos données personnelles</h4>

                            Wizall conservera vos données personnelles uniquement aussi longtemps que nécessaire aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et utiliserons vos données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales résoudre les litiges et faire respecter nos accords et politiques juridiques.

                            <br /> La Société conservera également les données d'utilisation à des fins d'analyse interne.

                            <br />Les données d'utilisation sont généralement conservées pendant une période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou pour améliorer la fonctionnalité de notre service, ou lorsque nous sommes légalement tenus de conserver ces données pendant des périodes plus longues.

                            <h4>Transferts internationaux des données</h4>

                            Vos informations, y compris vos données personnelles, sont traitées dans les bureaux opérationnels de Wizall et dans tout autre endroit où se trouvent les parties impliquées dans le traitement. Cela signifie que ces informations peuvent être transférées et conservées sur des ordinateurs situés en dehors de votre état, ville, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de votre juridiction.

                            <br />Votre consentement à cette politique de confidentialité suivi de votre soumission de ces informations représente votre accord à ce transfert.

                            <br />Wizall prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données sont traitées de manière sécurisée et conformément à la présente politique de confidentialité et aucun transfert de vos données personnelles n'aura lieu vers une organisation ou un pays à moins que des contrôles adéquats ne soient en place, y compris la sécurité de vos données et autres informations personnelles.

                            <br /><br /><h4>Supprimez vos données personnelles</h4>

                            Vous avez le droit de nous demander de supprimer les données personnelles que nous avons collectées à votre sujet.

                            <br />Vous pouvez mettre à jour vos informations à tout moment en vous connectant à votre compte, si vous en avez un, et en visitant la section des paramètres du compte qui vous permet de gérer vos informations personnelles. Vous pouvez également nous contacter pour demander l'accès, la correction ou la suppression de toute information personnelle que vous nous avez fournie.

                            <br />Veuillez toutefois noter que nous pouvons être amenés à conserver certaines informations lorsque nous avons une obligation légale ou une base légale de le faire.

                            <br /><br /><h4>Divulgation de vos données personnelles</h4>

                            <h4>Transactions commerciales</h4>

                            Si la Société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous vous informerons avant que vos données personnelles ne soient transférées et ne soient soumises à une politique de confidentialité différente.

                            <br /><br /><h4>Forces de l'ordre</h4>

                            Dans certaines circonstances, la Société peut être tenue de divulguer vos données personnelles si la loi l'y oblige ou en réponse à des demandes valables des autorités publiques.

                            <br /><br /><h4>Autres exigences légales</h4>

                            Wizall peut divulguer vos données personnelles en croyant de bonne foi qu'une telle action est nécessaire pour :
                            <br />
                            <ul>
                                <li>Se conformer à une obligation légale</li>
                                <li>Protéger et défendre les droits ou les biens de la Société</li>
                                <li>Prévenir ou enquêter sur d'éventuels actes répréhensibles en relation avec le Service</li>
                                <li>Protéger la sécurité personnelle des Utilisateurs du Service ou du public</li>
                                <li>Se protéger contre la responsabilité légale</li>
                            </ul>
                            <h4> Sécurité de vos données personnelles</h4>

                            La sécurité de vos données personnelles est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ni aucune méthode de stockage

                            électronique n'est sécurisée à 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.

                            <br /><br /><h4>Données Mineurs</h4>

                            Notre service ne s'adresse pas aux personnes de moins de 12 ans. Nous ne collectons sciemment aucune information personnelle identifiable auprès de toute personne de moins de 12 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des données personnelles, veuillez nous contacter. Si nous apprenons que nous avons collecté des données personnelles auprès d'une personne de moins de 12 ans sans vérification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.

                            Nous exigeons le consentement des parents avant de collecter et d'utiliser les informations des mineurs

                            <br /><br /><h4>Liens vers d'autres sites Web</h4>

                            Notre Service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons fortement de consulter la politique de confidentialité de chaque site que vous visitez.

                            Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers.

                            <br /><br /><h4> Modifications de cette politique de confidentialité</h4>

                            Nous nous réservons le droit de modifier cette Politique de confidentialité à tout moment. Les modifications entreront en vigueur dès leur publication sur notre application ou notre site web.

                            <br /> Nous vous en informerons par e-mail et/ou par un avis bien visible sur notre service, avant que le changement n'entre en vigueur et mettrons à jour la date de « Dernière mise à jour » en haut de cette politique de confidentialité.
                        </p>
                        <p> Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter : <br /><br />

                            ● Par email : mariama.diallo@wizall.com</p>
                    </Col>
                </Row>
            </div>
            <br />
        </div >
    );
}