/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Row, Col, Button } from 'antd';
import Banner2 from '../img/banner2.png'
import axios from 'axios';
import Footer from '../../Components/Footer/index';
import Header from '../../Components/Header/index';

class aides_contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            email: null,
            country: null,
            message: null,
            phone_number: null,
            markers: [],
            error: null,
            success: false,
        };
    }

    sendMail = (event) => {
        const name = this.getName.value;
        const email = this.getEmail.value;
        const subject = this.getSubjet.value;
        const message = this.getMessage.value;
        const phone_number = this.getPhone.value;
        const country = this.getCountry.value;

        if ((name === "") || (email === "") || (subject === "") || (message === "") || (phone_number === "") || (country === "")) {
            this.setState({
                error: 'Veuillez remplir tous les champs'
            })

        } else {
            axios.post(`https://www.wizallmoney.com/api/contact`, {
                name: name,
                email: email,
                subject: subject,
                message: message,
                phone_number: phone_number,
                country: country
            })
                .then((res) => {
                    // console.log(res);
                    this.setState({ success: true })
                    axios({
                        method: 'GET',
                        url: `https://www.wizallmoney.com/aides-contac/nouveau`
                    }).then((res) => {
                        console.log('res:', res.data);
                        console.log('res.data:', res);
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="aides_contact fadeIn wow">
                    <div className="section-1">
                        <img alt="" src={Banner2} />
                    </div>

                    <div className="section-2">
                        {
                            this.state.success ?
                                <div className="container">
                                    <div className="text-content success">
                                        <div className="">
                                            <h1>Merci de nous avoir contacter.</h1>
                                            <h4>Votre message a bien été envoyé. <br /> Nous reviendrons vers vous dans les meilleurs délais.</h4>
                                            <NavLink to='/accueil' className="btn">Ok</NavLink>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className="container">
                                    <div className="text-content">
                                        <h1>Prêt à Découvriz le meilleur de Wizall pour les pros ?</h1>
                                        <p>Êtes-vous prêt à découvrir comment Wizall pour les Pro, pourrait vous aidez à économiser de l'énergie et gagner du temps dans la gestion de vos flux financier.</p>
                                        <p>Renseigner le formulaire puis cliquez sur "Soumettre", afin de nous permettre de vous recontacter.</p>
                                    </div>
                                    <Row>
                                        <Col lg={10}>
                                            <form>
                                                <div className="form-group">
                                                    <p className="error_message">{this.state.error}</p>
                                                    <label>Pays:</label>
                                                    <select className="form-control" id="sel1" ref={(input) => this.getCountry = input}>
                                                        <option></option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="CI">Côte d'ivoire</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="SN">Sénégal</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Nom:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        ref={(input) => this.getName = input}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label>Email:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        ref={(input) => this.getEmail = input}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label>Telephone:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        ref={(input) => this.getPhone = input}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label>Sujet:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        ref={(input) => this.getSubjet = input}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label>Message:</label>
                                                    <textarea
                                                        className="form-control"
                                                        rows="40"
                                                        cols="500"
                                                        ref={(input) => this.getMessage = input}
                                                    />
                                                </div>
                                                <Button onClick={this.sendMail} className="btn">Soumettre</Button>
                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                        }

                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default aides_contacts;
