import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from 'antd';
import WizallMoney from './Components/wizallMoney';
import WizallMoneyPro from './Components/wizallMoneyPro';
import ReseauAgents from './Components/reseauAgents';
import AidesContact from './Components/AidesContact';
import ContratSn from './Components/contrats/contratsn';
import ContratMl from './Components/contrats/contratml';
import ContraCI from './Components/contrats/contratci';
import ContraBF from './Components/contrats/contratbf';
import 'antd/dist/antd.css';
import DgpsnPage from './Components/dgpsn-wizall';
import LandBotPage from './Components/landbot';
import HomePage from './Components/Accueil';
import MentionLegales from './Components/mention-legales';
import Privacy from './Components/privacy';
const { Content } = Layout;

export default function App() {
  return (
    <BrowserRouter>
      <Layout className="layout">
        <Content>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/accueil" element={<HomePage />} />
            <Route path="/wizall-money" element={<WizallMoney />} />
            <Route path="/wizall-money-pro" element={<WizallMoneyPro />} />
            <Route path="/reseau-agents" element={<ReseauAgents />} />
            <Route path="/aides-contact" element={<AidesContact />} />
            <Route path="/contrat-sn" element={<ContratSn />} />
            <Route path="/contrat-ml" element={<ContratMl />} />
            <Route path="/contrat-ci" element={<ContraCI />} />
            <Route path="/contrat-bf" element={<ContraBF />} />
            <Route path="/dgpsn-wizall" element={<DgpsnPage />} />
            <Route path="/mention-legales" element={<MentionLegales />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/bijou" element={<LandBotPage />} />
            {/* <Redirect to="/accueil" /> */}
          </Routes>
        </Content>
      </Layout>
    </BrowserRouter>
  );
}

// 