import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Banner2 from '../img/banner2.png'
import Banner3 from '../img/banner3.png'
import Phone from '../img/phone.png'
import Tablette from '../img/tablette.png'
import Partenaires from '../img/partenaires.png'
import Facebook from '../img/facebook.png'
import Twitter from '../img/twitter.png'
import Linkedin from '../img/linkedin.png'
import Instagram from '../img/instagram.png'
import { Carousel } from 'antd';
import Playgoogle from '../img/playgoogle.png'
import Appstore from '../img/appstore.png'
import Footer from '../../Components/Footer/index';
import Header from '../../Components/Header/index';

export default function HomePage() {
    useEffect(() => {
        new window.Landbot.Livechat({
            configUrl: 'https://chats.landbot.io/v3/H-1206689-9W9MQ1EMEEYQS0OX/index.json',
        });
    }, []);
    return (
        <div>
            <Header />
            <div className="home">
                <div className="section-1">
                    <Carousel autoplay>
                        <div>
                            <img src={Banner2} alt="Second slide" />
                        </div>
                        <div>
                            <img src={Banner3} alt="Second slide" />
                        </div>
                    </Carousel>
                </div>
                <div className="section-2">
                    <div className="container">
                        <h1 className="fadeIn wow">Utilisez nos applications et facilitez-vous la vie</h1>
                        <Row>
                            <Col lg={12} xs={12} className="blc">
                                <Link to='/wizall-money' className="nav-link">
                                    <img src={Phone} alt="" className="img-phone img-fluid" />
                                </Link>
                                <p><strong>Le compte Wizall Money</strong></p>
                                <p>Facilitez vous la vie avec le Wizall Money : payez vos factures et envoyez de l'argent à vos proches gratuitement.</p>
                                <Link to='/wizall-money' className="btn fadeIn wow"> Découvrir </Link>
                            </Col>
                            <Col lg={12} xs={12} className="blc">
                                <Link to='/wizall-money-pro' className="nav-link">
                                    <img src={Tablette} alt="" className="img-phone img-fluid" />
                                </Link>
                                <p><strong>Wizall Money Pro</strong></p>
                                <p>Payez les salaires de vos emplyés, vos factures et collectez facilement de l'argent avec Wizall Money pour les pros.</p>
                                <Link to='/wizall-money-pro' className="btn fadeIn wow"> Découvrir </Link>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section-3">
                    <div className="container">
                        <h2 className="fadeIn wow">Ils nous font confiance :</h2>
                        <img src={Partenaires} alt="" className="partenaires img-fluid fadeIn wow" />
                        <div>
                            <div className="appstore">
                                <h4>Téléchargez l'application:</h4>
                                <div className="store">
                                    <a href="https://play.google.com/store/apps/details?id=com.wizall.wizallclient&amp;referrer=utm_source%3DReferent%26utm_medium%3Dwizallsite%26utm_campaign%3DSite_acquisition%26anid%3Dadmo" target="_blank" rel="noopener noreferrer">
                                        <img src={Playgoogle} alt="" />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/wizall-money/id1203292720?l=fr&amp;ls=1" target="_blank" rel="noopener noreferrer">
                                        <img src={Appstore} alt="" />
                                    </a>
                                </div>
                            </div>

                            <h2>Suivez - nous:</h2>
                            <ul>
                                <li><a href="https://www.facebook.com/WizallMn" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="" className="img-fluid" /></a></li>
                                <li><a href="https://www.instagram.com/wizallmoney/" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="" className="img-fluid" /></a></li>
                                <li><a href="https://twitter.com/WizallMoney" target="_blank" rel="noopener noreferrer"><img src={Twitter} alt="" className="img-fluid" /></a></li>
                                <li><a href="https://www.linkedin.com/company/wizall-money/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="" className="img-fluid" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>);
}