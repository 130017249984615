import React from 'react';
import { Row, Col } from 'antd';
import Senegal from '../img/senegal.png'
import Civ from '../img/civ.png'
import Burkina from '../img/burkina.png'
import Mali from '../img/mali.png'


export default function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <Row>
                    <Col lg={6} xs={12} className="blc">
                        <img src={Senegal} alt="" />
                        <p><strong>Sénégal :</strong></p>
                        <p>+221 33 864 99 00</p>
                        <p>contact@wizall.com</p>
                        <p>8653 Sacrée Coeur 2, Dakar (en face BRT)</p>
                    </Col>
                    <Col lg={6} xs={12} className="blc">
                        <img src={Civ} alt="" />
                        <p><strong>Côte d'Ivoire :</strong></p>
                        <p>+225 27 22 41 09 42</p>
                        <p>contact-civ@wizall.com</p>
                        <p>ENA, en face de l'école Le Fleuron, Abidjan 2 Plateaux, Côte d'Ivoire</p>
                    </Col>
                    <Col lg={6} xs={12} className="blc">
                        <img src={Burkina} alt="" />
                        <p><strong>Burkina Faso : </strong></p>
                        <p>+226 25 39 27 10</p>
                        <p>contact-bf@wizall.com</p>
                        <p>Immeuble UPAK sis, Boulevard France Afrique,  Ouaga 2000, Burkina Faso </p>
                    </Col>
                    <Col lg={6} xs={12} className="blc">
                        <img src={Mali} alt="" />
                        <p><strong>Mali : </strong></p>
                        <p>+223 52 38 38 38</p>
                        <p>contact-ml@wizall.com</p>
                        <p>Quinzambougou, rue Achkhabab, face a l'hôtel LOFT, Mali</p>
                    </Col>
                </Row>
            </div>


            <div className="footer-text">
                2019 © Wizall Money est un service du groupe BCP
            </div>
            <br />
        </div>
    );
}